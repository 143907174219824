import classnames from "classnames";
import React from "react";

import * as styles from "./MobileNavButton.module.css";

const MobileNavButton = ({ toggleMobileMenu, open, invert }) => {
  const classes = classnames(styles.mobileNavButton, {
    [styles.active]: open,
    [styles.invert]: invert,
  });

  return (
    <button
      onClick={() => {
        toggleMobileMenu(!open);
      }}
      className={classes}
      aria-label={open ? "Close website navigation" : "Open website navigation"}
      aria-expanded={open}
    >
      <span className={styles.top} />
      <span className={styles.middle} />
      <span className={styles.bottom} />
    </button>
  );
};

export default MobileNavButton;
