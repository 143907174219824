import React from "react";
import { useScrollPosition, useWindowSize } from "../../utils/hooks";
import * as styles from "./ParallaxImage.module.css";

const ParallaxImage = ({ children, className, opposite }) => {
  const windowWidth = useWindowSize().width;
  const windowHeight = useWindowSize().height;
  const scrollY = useScrollPosition();
  const scrollPages = Math.round((scrollY / windowHeight) * 100) / 100;

  if (windowWidth > 1023) {
    return (
      <div className={`${className} ${styles.parallaxImage}`}>
        <div style={{ transform: `translate3d(0, ${(scrollPages <= 2 ? scrollPages * 160 : 160) * (opposite ? -1 : 1)}px, 0)` }}>
          {children}
        </div>
      </div>
    );
  } else {
    return children;
  }
};

export default ParallaxImage;
