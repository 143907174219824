import { graphql, useStaticQuery, Link } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import React from "react";
import IconInstagram from "../../assets/IconInstagram";
import IconLinkedIn from "../../assets/IconLinkedIn";
import IconTwitter from "../../assets/IconTwitter";
import IconEmployeeOwned from "../../assets/IconEmployeeOwned";
import IconBCorp from "../../assets/IconBCorp";
import iconIso27001 from "../../assets/Icon27001.png";
import Grid, { Col } from "../grid/Grid";
import Section from "../section/Section";
import * as styles from "./Footer.module.css";

const Footer = () => {
  const { contentfulFooter } = useStaticQuery(graphql`
    query {
      contentfulFooter {
        twitter
        linkedIn
        instagram
      }
    }
  `);

  const {
    twitter,
    linkedIn,
    instagram,
  } = contentfulFooter;
  
  return (
    <footer className={styles.footer}>
      <Section padding="both">
        <Grid>
          <Col start={1} span={2}>
            <div>
              <ul className={styles.listLinks}>
                <li><Link to="/what-we-do/" className="smallBodyText">What we do</Link></li>
                <li><Link to="/work/" className="smallBodyText">Work</Link></li>
                <li><Link to="/our-story/" className="smallBodyText">Our story</Link></li>
                <li><Link to="/join-us/" className="smallBodyText">Join us</Link></li>
                <li><Link to="/contact-us/" className="smallBodyText">Contact us</Link></li>
              </ul>
            </div>
          </Col>
          <Col start={3} span={2}>
            <div>
              <ul className={styles.listLinks}>
                <li><Link to="/blog/" className="smallBodyText">Insights</Link></li>
                <li><Link to="/employee-owned/" className="smallBodyText">Employee owned</Link></li>
                <li><Link to="/b-corp/" className="smallBodyText">B Corp</Link></li>
              </ul>
            </div>
          </Col>
          <Col start={5} span={4}>
            <div className={styles.wrapper}>
              <div className={styles.smallPrint}>
                <p className={`smallestBodyText ${styles.green}`}>This website is hosted on our own green network of servers located in regions with renewable energy output. <Link to="/blog/reducing-the-carbon-footprint-of-our-website/">Find out more</Link></p>
                <p className="smallestBodyText">Copyright © ustwo Ltd {new Date().getFullYear()}. All rights reserved. <Link to="/legal/">Legal</Link> | <Link to="/privacy-policy/">Privacy Policy</Link></p>
                <ul className={styles.socialNetworks}>
                  <li>
                    <a
                      href={instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconInstagram />
                    </a>
                  </li>
                  <li>
                    <a
                      href={linkedIn}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconLinkedIn />
                    </a>
                  </li>
                  <li>
                    <a 
                      href={twitter} 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      <IconTwitter />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col start={10} span={3}>
            <div className={styles.logos}>
              <IconEmployeeOwned fill="#ffffff" width="130" />
              <IconBCorp fill="#ffffff" width="70" />
              <img src={iconIso27001} alt="" style={{ width: "130px" }} />
            </div>
          </Col>
        </Grid>
      </Section>
    </footer>
  );
};

export default Footer;
