export default (string) => {
  if (typeof string !== "string") {
    return string;
  }

  const words = string.split(/[ -]+/);
  return words
    .map((word) => word[0].toUpperCase() + word.substring(1))
    .join(" ");
};
