import React from "react";

const IconLinkedIn = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
    <title>Linked In</title>
    <path d="M5.01 19.664V6.518H.64v13.146h4.37zM2.825 4.724c1.524 0 2.472-1.01 2.472-2.272C5.27 1.162 4.35.18 2.854.18S.382 1.162.382 2.452c0 1.26.948 2.27 2.414 2.27h.03zm4.81 14.94h4.37v-7.342c0-.393.03-.785.145-1.066.315-.785 1.034-1.598 2.242-1.598 1.58 0 2.213 1.206 2.213 2.973v7.036h4.37v-7.538c0-4.038-2.156-5.917-5.03-5.917-2.358 0-3.393 1.318-3.968 2.215h.03V6.518h-4.37c.057 1.233 0 13.146 0 13.146z" />
  </svg>
);

export default IconLinkedIn;
